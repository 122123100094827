@import 'src/styles/imports';

.periods {
  > div {
    margin-top: -40px;
  }

  > div > b {
    // display: none;
    height: 0;
    margin: 0;
    display: inline-block;
    // position: absolute;
  }
}

.period {
  h3 {
    font-size: 14px;
    margin: 0;
    padding: 10px 20px 0;
    cursor: grab;
    color: $white;

    form {
      width: 100%;
    }

    input {
      background-color: $blue-900;
      color: #fff;
      border: 0;
      border-radius: 4px;
      padding: 10px;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
    }
  }
}

.red {
  color: $red-attention;

  svg {
    stroke: $red-attention !important;
  }
}

.grey {
  color: $grey-200;

  svg {
    fill: none;
    stroke: $grey-200 !important;
  }
}

.handle {
  position: absolute;
  left: 0;
  top: 0;
  cursor: grab;
  height: 40px;
  width: 100%;
  z-index: 2;
}
