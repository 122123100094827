@import 'src/styles/imports';

.areaOfStudy {
  padding: 0 20px 20px;
  color: #fff;
  z-index: 3;
}

.header {
  display: flex;
  justify-content: space-between;
}

.editButton {
  background-color: transparent;
  border: 0;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  svg {
    stroke: #fff;
    height: 1rem;
    margin-left: 5px;
  }
}

.listOfAoS {
  margin-top: 10px;

  .list {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
    display: flex;
    flex-wrap: wrap;

    li {
      padding: 2px 10px;
      line-height: 1;
      white-space: nowrap;
      font-size: 14px;
      border-radius: 4px;
      margin: 0 4px 4px 0;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      max-width: 100%;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        stroke: currentColor;
        height: 1rem;
        width: 1rem;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

.warning {
  display:inline-flex;
  align-items: center;

  svg {
    stroke: $white;
  }
}

.count {
  display: inline-flex;
  justify-content: center;
  border: 1px solid $white;
  border-radius: 4px;
  min-width: 16px;
  margin: 2px;
  padding: 1px 2px;
}