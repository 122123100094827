$yellow-outline: #c0b76b;
$yellow: #fef49c;

.noteTag {
  z-index: 1;
  align-self: flex-end;
  margin: 0 10px 10px;

  .poke {
    position: absolute;
    width: 6px;
    height: 5px;
    background-color: $yellow;
    border-left: solid 1.5px $yellow-outline;
    border-top: solid 1.5px $yellow-outline;
    left: calc(50% - 3px);
    top: -4px;
    transform: rotate(45deg);
  }
  width: 24px;
  height: 18px;
  margin-left: auto;
  background-color: $yellow;
  border: solid 1.5px $yellow-outline;
  border-radius: 4px;

  &::before {
    content: '';
    top: 4px;
    width: 12px;
    @extend %line;
  }
  &::after {
    content: '';
    top: 8px;
    width: 8px;
    @extend %line;
  }
}

%line {
  position: absolute;
  height: 2px;
  left: calc(50% - 6px);
  border-radius: 2px;
  background-color: $yellow-outline;
}
