@import 'src/styles/imports';

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $blue-500;
  border-bottom: 1px solid $blue-600;
  color: #fff;
  padding: 0 20px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;

  svg {
    height: 24px;
    stroke: $white;
  }
}

.left > button,
.right > button,
.right > span > button {
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &.active {
    background-color: rgba(#000, 0.3);
  }
}

.left {
  white-space: nowrap;
  display: flex;

  button {
    //margin: 0 10px 0 0;

    &.active {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.right {
  display: flex;
  align-items: center;
  // button {
  //   margin: 0 0 0 10px;
  // }
}

.autosave {
  margin-right: 20px;

  svg {
    fill: #fff;
  }
}

// copy notification

@keyframes inOut {
  0% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  20% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  80% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  0% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
}

.copyNotification {
  opacity: 0;
  transform: translate(-50%, -10px);
  position: absolute;
  padding: 10px 20px;
  left: 50%;
  top: 10px;
  background-color: #fff;
  color: $black;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(#000, 0.3);
  animation: inOut 2s 1 forwards ease-in-out;
}

// display options

.displayOptions {
  opacity: 0;
  transform: translate(0, -10px);
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  color: $black;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 4px 8px rgba(#000, 0.3);

  h3 {
    padding: 20px;
    margin: 0;
    border-bottom: 1px solid $grey-100;
    white-space: nowrap;
    font-size: 1rem;
    text-align: center;

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;

      svg {
        stroke: $black;
        height: 1rem;
        width: 1rem;
        stroke-width: 2px;
      }
    }
  }

  .options {
    list-style: none;
    padding: 20px;
    margin: 0;

    li {
      font-weight: 500;
      display: flex;
      white-space: nowrap;
      align-items: center;

      span {
        flex: 1 0 auto;
        margin-right: 20px;
      }
    }
  }
}

@keyframes in {
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes out {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, -20px);
  }
}

.enter {
  animation: in 0.3s 1 forwards ease-in-out;
}

.exit {
  animation: out 0.3s 1 forwards ease-in-out;
}
