@import 'src/styles/imports';

.ChangeSummary {
  h2,
  h3 {
    color: inherit;
  }

  h2 {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    font-size: 16px;
  }

  @include res(m) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}

.viewOnly {
  .changedAoS,
  .changedUnits {
    background-color: $blue-600;
  }
}

.hideFromStudentView {
  display: flex;
  align-items: center;
  font-family: sans-serif;
  font-weight: 300;
  font-size: 14px;
  font-style: normal;

  span {
    margin-right: 10px;
  }
}

.changedAoS,
.changedUnits {
  border-radius: 4px;
  background-color: $blue-800;
  padding: 20px;
  border: 1px solid #fff;
  color: #fff;

  h4 {
    font-size: 16px;
    margin: 0 0 20px;
  }

  .h5 {
    font-size: 14px;
    margin: 0 0 20px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 20px;
    }

    li {
      margin: 0 0 10px;
      max-width: 100%;

      &:last-child {
        margin: 0;
      }

      div {
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 0 10px 0 0;

        span {
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0;
        }
      }

      svg {
        height: 10px;
        stroke-width: 5;
        margin-right: 5px;
        flex: 0 0 auto;
      }
    }
  }

  .year:last-child .teachingPeriod:last-child ul:last-child {
    margin-bottom: 0;
  }
}

.changedAoS ul:last-child {
  margin-bottom: 0;
}

.subractedUnits,
.subtractedAoS {
  div {
    border: 1px solid $red-attention;
    background-color: rgba($red-attention, 0.2);
    color: $red-tinted-attention;

    svg {
      stroke: $red-attention;
    }
  }
}

.addedUnits,
.addedAoS {
  div {
    border: 1px solid $green-success;
    background-color: rgba($green-success, 0.2);
    color: $green-tinted-success;

    svg {
      stroke: $green-success;
    }
  }
}

// comments

.ChangeSummaryComment {
  background-color: $blue-800;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
  color: #fff;
  word-break: break-all;

  a {
    color: #fff;
  }
}

.textArea {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-family: inherit;
  color: inherit;
}

// context menu

.red {
  color: $red-attention;

  svg {
    stroke: $red-attention;
  }
}
