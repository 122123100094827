@import 'src/styles/imports';

.year {
  h2 {
    font-size: 18px;
    margin: 0;
    padding: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: wait;

    &:hover {
      .buttons {
        opacity: 1;
      }
    }
  }

  .buttons {
    opacity: 0;
    transition: all 0.3s ease-in-out;

    button {
      border: 0;
      border-radius: 4px;
      padding: 4px;
      background-color: transparent;
      color: #fff;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: rgba(#000, 0.3);
      }

      svg {
        stroke: currentColor;
      }
    }
  }
}

.newYear {
  width: 100%;
  padding: 20px;

  button {
    background-color: transparent;
    border: 0;
    color: #fff;
    width: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: rgba(#000, 0.1);
    }

    svg {
      margin-right: 10px;
      stroke: #fff;
      width: 1rem;
      height: 1rem;
    }
  }
}

.red {
  color: $red-attention;

  svg {
    stroke: $red-attention;
  }
}

.grey {
  color: $grey-200;

  svg {
    fill: none;
    stroke: $grey-200 !important;
  }
}

.rightMargin {
  margin-right: 20px;
}
