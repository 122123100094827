@import 'src/styles/imports';

.content {
  background-color: $grey-background;
  height: 100%;
  overflow: auto;
  padding: 80px 20px 20px;

  // header
  header {
    .banner {
      border-radius: 4px;
      overflow: hidden;

      label {
        @extend %label;
        top: 0;
        left: 0;
        position: absolute;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .labelAlt {
        @extend %label;
        border-radius: 4px;
      }

      img {
        height: 150px;
        width: 100%;
        display: block;
        object-fit: cover;
        object-position: center center;
      }

      .loading {
        height: 150px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .title {
      width: 100%;
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      > h1 {
        margin: 0;
      }

      .actionButtons {
        width: 100%;
        flex-basis: 0;
        margin-left: 20px;
        display: flex;
        align-items: center;

        .removeBookmarkButton {
          @extend %iconButton;
          svg {
            path {
              fill: $blue-500;
            }
          }
        }

        .addBookmarkButton {
          @extend %iconButton;
        }

        fieldset {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: transparent;
          border: none;
          padding: 0;
          margin: 0;

          select {
            opacity: 0;
            position: absolute;
            width: 100%;
            z-index: 2;

            &:hover {
              cursor: pointer;
            }
          }
          
          .dropdown {
            // -moz-appearance: none; /* Firefox */
            // -webkit-appearance: none; /* Safari and Chrome */
            // appearance: none;
            // background-color: transparent;
            // border: none;
            display: flex;
            color: $grey-900;
            font-size: 16px;
            font-weight: 400;
            z-index: 1;
          }

          svg {
            //width: 100%;
            fill: $grey-900;
          }
        }
      }
    }

    p {
      margin: 0 0 20px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  // section

  section {
    background-color: #fff;
    border: 1px solid $grey-100;
    box-shadow: 0 1px 1px rgba($black, 0.3);
    border-radius: 4px;
    margin-bottom: 20px;

    h2 {
      font-size: 16px;
      //padding: 20px;
      margin: 0;
    }
  }
}

%label {
  width: fit-content;
  display: block;
  padding: 4px 10px;
  background-color: $blue-500;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  &::first-letter {
    text-transform: capitalize;
  }
}

%iconButton {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border: none;
    background: none;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
  svg {
    stroke: $blue-500;
  }
}

// hr

hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: $grey-100;
  margin: 20px 0;
}
