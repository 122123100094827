@import 'src/styles/imports';

.search {
  &:focus-within {
    .suggested {
      opacity: 1;
      transform: translate(0, 0);
      visibility: visible;
    }
  }
}

.input {
  background-color: $blue-700;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;

  input {
    color: #fff;
    font-size: 1rem;
    display: block;
    flex: 1 1 auto;
    border: 0;
    background-color: transparent;

    &::placeholder {
      color: $blue-tinted-300
    }
  }

  button {
    background-color: #fff;
    border: 0;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background-color: $grey-100;
    }
  }
}

.suggested {
  opacity: 0;
  visibility: hidden;
  max-height: 10rem;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  top: calc(100% + 10px);
  border: 1px solid $grey-100;
  border-radius: 4px;
  background-color: #fff;
  color: $black;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(#000, 0.3);
  transition: all 0.3s ease-in-out;
  transform: translate(0, -10px);
  z-index: 2;

  li {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: $grey-100;
    }
  }
}

.secondary {
  color: $grey-800;
}
