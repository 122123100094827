@import 'src/styles/imports';

.advancedStanding + .advancedStanding {
  border-top: 1px solid $grey-100;
  margin-top: 20px;
  padding-top: 20px;
}

section {
  .sectionContents {
    padding: 20px;
    overflow-wrap: break-all;
    width: 100%;
    border-bottom: 1px solid $grey-100;

    a {
      text-decoration: none;
      color: $blue-500;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.courseProgress {
    > h2 {
      font-size: 1rem;
      margin: 0 0 20px;
    }
  }
}

.keyBlob {
  display: inline-block;
  height: 10px;
  width: 1rem;
  margin: 0 5px 0 0;
  border-radius: 8px;
}

.note {
  margin: 0 20px 20px 20px;
  color: $grey-900;
  white-space: pre-wrap;

  .noteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    .type {
      font-weight: 500;
    }
  }

  .secondary {
    color: $grey-800;
    font-size: 14px;
  }
}

.showMore {
  color: $blue-500;
  width: 100%;
  text-align: center;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
