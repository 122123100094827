@import 'src/styles/imports';

.advancedStandings {
  h2 {
    color: #fff;
    padding: 20px 20px 0;
    margin: 0;
  }
}

.container {
  padding: 20px;
  display: grid;
  grid: auto-flow / repeat(2, 1fr);
  gap: 10px;
}

.disabled {
  position: absolute;
  background-color: $blue-900;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: 2;
}