@import 'src/styles/imports';

.creditPointsTag {
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: $blue-500;
  background-color: $blue-tinted-100;
  border: 1px solid $blue-tinted-200;
  border-radius: 50px;
  padding: 2px 6px;
  margin: 0 10px 10px;
}
