@import 'src/styles/imports';

.planner {
  background-color: $blue-500;
  height: 100vh;
  overflow: visible;
  flex: 1 0 auto;
  transition: all 0.5s ease-in-out;
}

.plannerContent {
  padding-top: 60px;
  height: 100%;
  overflow: auto;
  color: #fff;
}

// edit mode

.editMode {
  background-color: $blue-700;
  border: 1px solid #fff;
  margin: 20px;
  border-radius: 4px;

  > h3 {
    color: #fff;
    text-align: center;
  }
}

.notEditMode {
  background-color: $blue-600;
}

.confirmationButton {
  display: flex;
  justify-content: center;
  padding: 20px;

  button {
    background-color: #fff;
    border: 0;
    border-radius: 500px;
    font-weight: 500;
    font-size: 1rem;
    padding: 5px 20px;
    color: $blue-500;
    cursor: pointer;
  }
}

// change summary

.changeSummary {
  padding: 20px;
}
