@import 'src/styles/imports';
.planNameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  > div {
    width: 100%;
    justify-content: space-between;
  }
  h2 {
    font-size: 16px;
    margin: 0;
    padding: 10px 20px 0;
    cursor: grab;
    color: $white;
    width: 100%;
    justify-content: space-between;

    form {
      width: 100%;
    }

    input {
      background-color: $blue-900;
      color: #fff;
      border: 0;
      border-radius: 4px;
      padding: 10px;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
    }
  }
}
