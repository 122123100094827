@import 'src/styles/imports';

$yellow: #fef49c;

@keyframes dropIn {
  from {
    opacity: 0;
    transform: translate(0, -5px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.Note {
  background-color: $yellow;
  padding: 6px 10px;
  border-radius: 4px;
  color: $black;
  margin-top: 6px;
  border-bottom: 2px solid rgba(#000, 0.3);
  filter: drop-shadow(0px 1px 0px rgba(#000, 0.3));
  z-index: 2;
  // animation: dropIn 0.5s forwards ease-in-out;

  .textArea {
    border: 0;
    font-family: inherit;
    font-size: inherit;
    background-color: inherit;
    color: $black;
  }
}

.poke {
  width: 10px;
  height: 10px;
  background-color: $yellow;
  position: absolute;
  left: calc(50% - 5px);
  top: -5px;
  transform: rotate(45deg);
}

.noteContent {
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-wrap: anywhere;
}
